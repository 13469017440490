@charset "UTF-8";

@import "bootstrap/functions";

@import "variables";

@import "bootstrap/bootstrap";

 :focus {
  outline: 0 !important; }

body {
  position: relative; }

#header {
  z-index: $zindex-fixed !important; }

#header {
  border-top: 2px solid theme-color('primary'); }

.headroom--not-top {
  box-shadow: 0 0 10px 0 rgba($black, 0.24);
  .navbar {
    padding: $navbar-padding-y/2 $navbar-padding-x; }
  .navbar-brand {
    padding-left: 3.5rem;
    img {
      height: 100%; } } }

.text-zona {
  font: {
    size: 1.6rem;
    family: 'Zona Pro SemiBold'; }
  color: $white;
  opacity: 0.9;
  line-height: 1.07575;
  text-transform: uppercase;
  margin-bottom: 1.875rem;
  text-shadow: 0 0.125rem 0.25rem rgba($black, 0.5);
  span {
    display: block;
    font-family: 'Zona Pro Hairline'; }
  @include media-breakpoint-up(sm) {
    font: {
      size: 2rem; } }
  @include media-breakpoint-up(md) {
    font: {
      size: 2.6rem; } } }

.text-zona-2 {
  color: $white;
  font: {
    size: 1rem;
    family: 'Zona Pro SemiBold'; }
  text-transform: uppercase;
  margin: 0 0 2rem;
  @include media-breakpoint-up(sm) {
    font: {
      size: 1.25rem; } }
  @include media-breakpoint-up(md) {
    font: {
      size: 1.5rem; } }
  @include media-breakpoint-up(lg) {
    font: {
      size: 1.875rem; } } }

@include media-breakpoint-up(lg) {
  .text-zona {
    font-size: 4.125rem; } }

.hotline-btn {
  border-radius: 0; }

.navbar-brand {
  position: relative;
  font-weight: 300;
  padding-left: 4.75rem;
  img {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    height: auto;
    transition: $transition-base; } }

.page-bg {
  position: absolute;
  display: block;
  z-index: 0;
  width: 100%;
  max-height: 940px;
  min-height: 100vh;
  background: transparent url('../img/header-bg.jpg') no-repeat center center;
  background-size: cover;
  &__inner {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: theme-color('primary');
    opacity: 0.51; } }

.inner-page-bg {
  background: transparent url('../img/page-bg.jpg') no-repeat;
  background-position: right center;
  background-size: cover;
  @include media-breakpoint-up(md) {
    background-position: center center; } }

.text {
  font: {
    size: 1.125rem; }
  line-height: 1.5909;

  ul {
    padding-left: 1.125rem; }

  @include media-breakpoint-up(md) {
    font-size: 1.375rem;
    ul {
      padding-left: 1.375rem; } } }

.text-2 {
  font: {
    size: 1.25rem; }
  line-height: 1.75;
  color: #757575;
  h4 {
    color: inherit; } }
.item {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font: {
    weight: $font-weight-light;
    size: 1rem; }
  &__title {
    font-weight: $font-weight-bold; }
  &__caption {
    font-size: 1.375rem; }
  &__link {
    color: theme-color('primary');
    font-size: 1.0625rem; }
  &__app {
    font: {
      size: 1rem;
      weight: $font-weight-light; }
    color: $text-muted;

    p {
      margin: 0;
      line-height: 1.5625;
      &:first-child {
        color: #8d967c;
        font-size: 1.0625rem;
        line-height: 2; } } }

  @include media-breakpoint-up(xl) {
    font-size: 1.25rem; } }

.btn-warning {
  color: $body-color;
  box-shadow: 0 0.125rem 0 0 #8b572a;
  @include hover-focus {
    box-shadow: 0 0.125rem 0 0 #8b572a; }
  &.btn-lg {
    color: $black; }
  &:active,
  &.active,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0.125rem 0 0 #8b572a; } }

.modal-header,
.modal-body {
  padding: 2rem 1.25rem;
  @include media-breakpoint-up(md) {
    padding: 40px; }
  @include media-breakpoint-up(lg) {
    padding: 50px; } }

.modal-header {
  padding-bottom: 0; }
