// stylelint-disable declaration-no-important

.shadow-sm {
  box-shadow: $box-shadow-sm !important; }

.shadow {
  box-shadow: $box-shadow !important; }

.shadow-lg {
  box-shadow: $box-shadow-lg !important; }

.shadow-none {
  box-shadow: none !important; }

.h4-shadow {
  text-shadow: 0 0.125rem 0 rgba($black, 0.5); }
