@keyframes progress-bar-stripes {
  from {
    background-position: $progress-height 0; }

  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: $progress-height;
  overflow: hidden;

  // force rounded corners by cropping it
  font-size: $progress-font-size;
  background-color: $progress-bg;

  @include border-radius($progress-border-radius);
  @include box-shadow($progress-box-shadow); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $progress-bar-color;
  text-align: center;
  white-space: nowrap;
  background-color: $progress-bar-bg;

  @include transition($progress-bar-transition); }

.progress-bar-striped {
  @include gradient-striped;

  background-size: $progress-height $progress-height; }

.progress-bar-animated {
  animation: progress-bar-stripes $progress-bar-animation-timing; }
