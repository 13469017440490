

@font-face {
  font-family: 'Zona Pro Hairline';
  src: url('../fonts/ZonaPro-HairlineItalic.eot');
  src: url('../fonts/ZonaPro-HairlineItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/ZonaPro-HairlineItalic.woff2') format('woff2'), url('../fonts/ZonaPro-HairlineItalic.woff') format('woff'), url('../fonts/ZonaPro-HairlineItalic.ttf') format('truetype'), url('../fonts/ZonaPro-HairlineItalic.svg#ZonaPro-HairlineItalic') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Zona Pro SemiBold';
  src: url('../fonts/ZonaPro-SemiBoldItalic.eot');
  src: url('../fonts/ZonaPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/ZonaPro-SemiBoldItalic.woff2') format('woff2'), url('../fonts/ZonaPro-SemiBoldItalic.woff') format('woff'), url('../fonts/ZonaPro-SemiBoldItalic.ttf') format('truetype'), url('../fonts/ZonaPro-SemiBoldItalic.svg#ZonaPro-SemiBoldItalic') format('svg');
  font-weight: normal;
  font-style: normal; }
